import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'

export const IdResumeBanner = (props: any) => {
    const { name } = props
    return (
        <div className={`${styles.formBanner} ${responsiveStyles.formBanner}`}>
            <Wrapper>
                <div>
                    <h1>Welcome back {name}</h1>
                    <p>Complete your mis-sold motor finance application with Sentinel Legal.</p>
                </div>
            </Wrapper>
        </div>
    )
}
