export const lenderData = [
    { value: "Advantage Finance Ltd", label: "Advantage Finance Ltd" },
    { value: "Aldermore Bank Asset Finance", label: "Aldermore Bank Asset Finance" },
    { value: "Alfa Romeo Consumer Services", label: "Alfa Romeo Consumer Services" },
    { value: "Alfa Romeo Financial Services", label: "Alfa Romeo Financial Services" },
    { value: "Alphabet (GB) Limited", label: "Alphabet (GB) Limited" },
    { value: "Alphera Financial Services", label: "Alphera Financial Services" },
    { value: "Audi Finance", label: "Audi Finance" },
    { value: "Auto Expert Finance", label: "Auto Expert Finance" },
    { value: "Barclays Bank SAU", label: "Barclays Bank SAU" },
    { value: "Barclays Partner Finance", label: "Barclays Partner Finance" },
    { value: "Billing Finance", label: "Billing Finance" },
    { value: "Blackhorse Ltd", label: "Blackhorse Ltd" },
    { value: "Blue Motor Finance Limited", label: "Blue Motor Finance Limited" },
    { value: "BMW Finance", label: "BMW Finance" },
    { value: "Chrysler Group Financial Services", label: "Chrysler Group Financial Services" },
    { value: "Citroen Financial Services", label: "Citroen Financial Services" },
    { value: "Close Brothers Limited", label: "Close Brothers Limited" },
    { value: "Clydesdale Finance", label: "Clydesdale Finance" },
    { value: "Creation Financial Services Ltd", label: "Creation Financial Services Ltd" },
    { value: "DS Financial Services", label: "DS Financial Services" },
    { value: "FCA Automotive Services UK Ltd", label: "FCA Automotive Services UK Ltd" },
    { value: "FCE Bank PLC (Ford)", label: "FCE Bank PLC (Ford)" },
    { value: "Fiat Financial Services", label: "Fiat Financial Services" },
    { value: "Firstplus", label: "Firstplus" },
    { value: "Hitachi Capital Consumer Finance", label: "Hitachi Capital Consumer Finance" },
    { value: "Honda Finance Europe PLC", label: "Honda Finance Europe PLC" },
    { value: "Hyundai Capital UK Ltd", label: "Hyundai Capital UK Ltd" },
    { value: "Hyundai Car Finance Ltd", label: "Hyundai Car Finance Ltd" },
    { value: "Jaguar", label: "Jaguar" },
    { value: "Jaguar Cars Finance", label: "Jaguar Cars Finance" },
    { value: "Kia Finance", label: "Kia Finance" },
    { value: "Land Rover Finance", label: "Land Rover Finance" },
    { value: "Landrover", label: "Landrover" },
    { value: "Lex Vehicle Finance", label: "Lex Vehicle Finance" },
    { value: "Mercedes-Benz Financial Services UK Ltd", label: "Mercedes-Benz Financial Services UK Ltd" },
    { value: "MG Financial Services GB Ltd", label: "MG Financial Services GB Ltd" },
    { value: "Mitsubishi HC Capital UK PLC", label: "Mitsubishi HC Capital UK PLC" },
    { value: "Moneybarn", label: "Moneybarn" },
    { value: "Motonovo Finance", label: "Motonovo Finance" },
    { value: "N.I.I.B T/A Northridge Finance (Nissan)", label: "N.I.I.B T/A Northridge Finance (Nissan)" },
    { value: "Paragon Car Finance", label: "Paragon Car Finance" },
    { value: "PCF Credit Limited", label: "PCF Credit Limited" },
    { value: "Peugeot Financial Services", label: "Peugeot Financial Services" },
    { value: "PSA Finance UK Ltd", label: "PSA Finance UK Ltd" },
    { value: "RCI Financial Services Ltd", label: "RCI Financial Services Ltd" },
    { value: "Santander Consumer (UK) PLC", label: "Santander Consumer (UK) PLC" },
    { value: "Secure Trust Bank PLC", label: "Secure Trust Bank PLC" },
    { value: "Shawbrook Bank Ltd", label: "Shawbrook Bank Ltd" },
    { value: "Shogun Finance Ltd", label: "Shogun Finance Ltd" },
    { value: "Speacialist Motor Finance Ltd", label: "Speacialist Motor Finance Ltd" },
    { value: "Stellantis Financial Services UK Limited", label: "Stellantis Financial Services UK Limited" },
    { value: "Suzuki Financial Services Ltd", label: "Suzuki Financial Services Ltd" },
    { value: "Toyota Financial Services", label: "Toyota Financial Services" },
    { value: "Toyota Financial Services (UK) PLC", label: "Toyota Financial Services (UK) PLC" },
    { value: "Vauxhall Finance / GMAC", label: "Vauxhall Finance / GMAC" },
    { value: "Volkswagen Finance", label: "Volkswagen Finance" },
    { value: "Volvo Financial Services Ltd", label: "Volvo Financial Services Ltd" },
];

export const lenderDataMain = [
    { value: "Audi Finance", label: "Audi Finance" },
    { value: "Blackhorse Ltd", label: "Blackhorse Ltd" },
    { value: "Barclays Partner Finance", label: "Barclays Partner Finance" },
    { value: "BMW Finance", label: "BMW Finance" },
    { value: "Close Brothers Limited", label: "Close Brothers Limited" },
    { value: "Mercedes-Benz Financial Services UK Ltd", label: "Mercedes-Benz Financial Services" },
    { value: "Motonovo Finance", label: "Motonovo Finance" },
    { value: "Santander Consumer (UK) PLC", label: "Santander Consumer (UK) PLC" },
    { value: "Vauxhall Finance / GMAC", label: "Vauxhall Finance / GMAC" },
    { value: "Volkswagen Finance", label: "Volkswagen Finance" },
];

export const lenderDataSecondary = [
    { value: "Advantage Finance Ltd", label: "Advantage Finance Ltd" },
    { value: "Aldermore Bank Asset Finance", label: "Aldermore Bank Asset Finance" },
    { value: "Alfa Romeo Consumer Services", label: "Alfa Romeo Consumer Services" },
    { value: "Alfa Romeo Financial Services", label: "Alfa Romeo Financial Services" },
    { value: "Alphabet (GB) Limited", label: "Alphabet (GB) Limited" },
    { value: "Alphera Financial Services", label: "Alphera Financial Services" },
    { value: "Auto Expert Finance", label: "Auto Expert Finance" },
    { value: "Barclays Bank SAU", label: "Barclays Bank SAU" },
    { value: "Billing Finance", label: "Billing Finance" },
    { value: "Blue Motor Finance Limited", label: "Blue Motor Finance Limited" },
    { value: "Chrysler Group Financial Services", label: "Chrysler Group Financial Services" },
    { value: "Citroen Financial Services", label: "Citroen Financial Services" },
    { value: "Clydesdale Finance", label: "Clydesdale Finance" },
    { value: "Creation Financial Services Ltd", label: "Creation Financial Services Ltd" },
    { value: "DS Financial Services", label: "DS Financial Services" },
    { value: "FCA Automotive Services UK Ltd", label: "FCA Automotive Services UK Ltd" },
    { value: "FCE Bank PLC (Ford)", label: "FCE Bank PLC (Ford)" },
    { value: "Fiat Financial Services", label: "Fiat Financial Services" },
    { value: "Firstplus", label: "Firstplus" },
    { value: "Hitachi Capital Consumer Finance", label: "Hitachi Capital Consumer Finance" },
    { value: "Honda Finance Europe PLC", label: "Honda Finance Europe PLC" },
    { value: "Hyundai Capital UK Ltd", label: "Hyundai Capital UK Ltd" },
    { value: "Hyundai Car Finance Ltd", label: "Hyundai Car Finance Ltd" },
    { value: "Jaguar", label: "Jaguar" },
    { value: "Jaguar Cars Finance", label: "Jaguar Cars Finance" },
    { value: "Kia Finance", label: "Kia Finance" },
    { value: "Land Rover Finance", label: "Land Rover Finance" },
    { value: "Landrover", label: "Landrover" },
    { value: "Lex Vehicle Finance", label: "Lex Vehicle Finance" },
    { value: "MG Financial Services GB Ltd", label: "MG Financial Services GB Ltd" },
    { value: "Mitsubishi HC Capital UK PLC", label: "Mitsubishi HC Capital UK PLC" },
    { value: "Moneybarn", label: "Moneybarn" },
    { value: "N.I.I.B T/A Northridge Finance (Nissan)", label: "N.I.I.B T/A Northridge Finance (Nissan)" },
    { value: "Paragon Car Finance", label: "Paragon Car Finance" },
    { value: "PCF Credit Limited", label: "PCF Credit Limited" },
    { value: "Peugeot Financial Services", label: "Peugeot Financial Services" },
    { value: "PSA Finance UK Ltd", label: "PSA Finance UK Ltd" },
    { value: "RCI Financial Services Ltd", label: "RCI Financial Services Ltd" },
    { value: "Secure Trust Bank PLC", label: "Secure Trust Bank PLC" },
    { value: "Shawbrook Bank Ltd", label: "Shawbrook Bank Ltd" },
    { value: "Shogun Finance Ltd", label: "Shogun Finance Ltd" },
    { value: "Speacialist Motor Finance Ltd", label: "Speacialist Motor Finance Ltd" },
    { value: "Stellantis Financial Services UK Limited", label: "Stellantis Financial Services UK Limited" },
    { value: "Suzuki Financial Services Ltd", label: "Suzuki Financial Services Ltd" },
    { value: "Toyota Financial Services", label: "Toyota Financial Services" },
    { value: "Toyota Financial Services (UK) PLC", label: "Toyota Financial Services (UK) PLC" },
    { value: "Volvo Financial Services Ltd", label: "Volvo Financial Services Ltd" },
];
