import styles from './styles.module.scss'
import pageStyles from '../../../globals/pages.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import menuStyles from './menu-styles.module.scss'
import { CtaButton, Wrapper } from '../../reusable'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import profile from '../../../../../assets/profile.png'
import { IsMobile } from '../isMobile/isMobile'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import arrow from '../../../assets/arrow-down.svg'

function Header() {
    const [clientName, setClientName] = useState<String>('')
    const [displayMenu, setDisplayMenu] = useState<Boolean>(false)
    const [displaySubMenu, setDisplaySubMenu] = useState<Boolean>(false)
    const { token } = useParams()
    const navigate = useNavigate()
    const sentinel = 'https://sentinellegal.co.uk'

    const scrollToFaqs = () => {
        setDisplayMenu(false)
        let faqs = document.getElementsByClassName('faq-elem')
        if (faqs.length > 0) {
            faqs[0].scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            })
        } else {
            console.log('could not find the FAQS')
            navigate('/#faqs')
            const faqs = document.getElementsByClassName('faq-elem')
            if (faqs.length > 0) {
                faqs[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                })
            }
        }
    }

    const toggleMenu = () => {
        console.log('TOGGLE....', displayMenu)
        setDisplayMenu(!displayMenu)
    }

    const toggleSubMenu = () => {
        console.log('TOGGLE....', displaySubMenu)
        setDisplaySubMenu(!displaySubMenu)
    }
    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                {IsMobile() ? (
                    <>
                        <div className={responsiveStyles.mobileMenu}>
                            <div className={`${styles.logo} ${responsiveStyles.logo}`}>
                                <a href="https://sentinellegal.co.uk/">
                                    <img src={logo} alt={'Sentinel Logo'} />
                                </a>
                            </div>
                            <div className={responsiveStyles.burgerContainer} onClick={() => toggleMenu()}>
                                <div
                                    className={`${styles.wrapperMenu} ${menuStyles.wrapperMenu} ${
                                        displayMenu ? menuStyles.open : ''
                                    }`}
                                >
                                    <div
                                        className={`${menuStyles.lineMenu} ${menuStyles.half} ${menuStyles.start}`}
                                    ></div>
                                    <div className={`${menuStyles.lineMenu}`}></div>
                                    <div
                                        className={`${menuStyles.half} ${menuStyles.lineMenu} ${menuStyles.end}`}
                                    ></div>
                                </div>
                            </div>

                            <div
                                className={`${displayMenu ? responsiveStyles.displayMenu : ''} ${
                                    styles.menuContainer
                                } ${responsiveStyles.menuContainer} `}
                            >
                                <div className={`${styles.menu} ${responsiveStyles.menu} `}>
                                    <nav>
                                        <ul>
                                            <li>
                                                <a href={`${sentinel}/about-us`}>About Us</a>
                                            </li>
                                            <li className={responsiveStyles.colItem}>
                                                <span onClick={() => toggleSubMenu()}>
                                                    Claims & Services{' '}
                                                    <img
                                                        className={`${displaySubMenu ? responsiveStyles.flip : ''}`}
                                                        src={arrow}
                                                    />
                                                </span>
                                                <ul
                                                    className={`${responsiveStyles.subMenu} ${
                                                        displaySubMenu ? responsiveStyles.displaySubMenu : ''
                                                    }  `}
                                                >
                                                    <li>
                                                        <a href={`${sentinel}/dcacommission`}>
                                                            What is a DCA commission
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${sentinel}/our-services`}>Car Finance Claims</a>
                                                    </li>
                                                    <li>
                                                        <a href={`${sentinel}/business-engery-claims`}>
                                                            Business Energy Claims
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <a href={`${sentinel}/news`}>News</a>
                                            </li>
                                            <li>
                                                <a href={`${sentinel}/recentwins`}>Recent Wins</a>
                                            </li>
                                            <li>
                                                <a href={`${sentinel}/contact-us`}>Contact Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.logo}>
                            <a href="https://sentinellegal.co.uk/">
                                <img src={logo} alt={'Sentinel Logo'} />
                            </a>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <a href="https://www.sentinellegal.co.uk/about-us">About Us</a>
                                </li>
                                <li className={styles.subNavLi}>
                                    <span>Claims & Services</span>
                                    <ul className={styles.subNav}>
                                        <li>
                                            <a href="https://www.sentinellegal.co.uk/dcacommission">
                                                What is a DCA commission
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.sentinellegal.co.uk/our-services">
                                                Car Finance Claims
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.sentinellegal.co.uk/business-engery-claims">
                                                Business Energy Claims
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.sentinellegal.co.uk/irresponsible-lending-claims">
                                                Irresponsible Lending Claims
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.sentinellegal.co.uk/news">News</a>
                                </li>
                                <li>
                                    <a href="https://www.sentinellegal.co.uk/recentwins">Recent Wins</a>
                                </li>
                                <li>
                                    <a href="https://www.sentinellegal.co.uk/contact-us">Contact Us</a>
                                </li>
                                <li>
                                    <a href="https://claim.sentinellegal.co.uk/start">Make a Claim</a>
                                </li>
                            </ul>
                        </nav>
                    </>
                )}
            </div>
        </header>
    )
}

export default Header
