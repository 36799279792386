import { useState } from 'react'
import styles from './styles.module.scss'
import { FieldGroup, FormCard } from '../../reusable'

import { FormPartInterface } from '../../../interfaces'
export const Complete = (props: FormPartInterface) => {
    const { isActive } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)

    const getCurrentStep = () => {
        return isActive == 'complete' ? (
            <FieldGroup title="Claim Complete">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <h2 style={{ textAlign: 'center' }}>Thank you!</h2>
                    <p className={styles.tagline}>
                        Your application has been successfully submitted.
                        <br /> <br /> A member of the Sentinel Legal team will be in touch soon.
                    </p>
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
