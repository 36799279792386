import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import {
    InputFieldText,
    InputFieldSelect,
    FieldGroup,
    Field,
    SubmitButton,
    ButtonContainer,
    FieldContainer,
    FormCard,
} from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { SignaturePad } from '../../reusable/sigPad'
import { sectionValidation } from '../../../helpers'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import { useClaimData } from '../../../claimProvider'
import Previewer from '../../reusable/previewer'

export const SignatureSentinel = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})
    // const [cases, setCases] = useState<any>([{ leadData: { lender: "audi finance", case_ref: "pcpSNT_234" }, cfaUrl: "T", loaUrl: "YT" }]);
    const [cases, setCases] = useState<any>([])
    const [sigLinkToken, setSigLinkToken] = useState<string>('')
    const claim = useClaimData()
    useEffect(() => {
        console.log('claim data: ', claim)
        if (claim?.caseListData != null && claim.caseListData.length > 0) {
            setCases(claim.caseListData)
        }
    }, [claim])
    useEffect(() => {
        console.log('cases : ', cases)
        if (cases.length > 0) {
            setSigLinkToken(cases[0].sigData.token)
        }
    }, [cases])

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)
        return saved
    }

    const animateBeforeAssign = (assignment: string) => {
        console.log(stepData)

        if (!sectionValidation(stepData, 'signature').success) return
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const getCurrentStep = () => {
        return isActive == 'signature' ? (
            <FieldGroup title="Sign Your Letter of Authority">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <div className={`${styles.sentinelSig} ${responsiveStyles.sentinelSig}`}>
                        <h3 className={`${styles.topTitle} ${responsiveStyles.topTitle}`}>
                            Please review and sign your documents instructing Sentinel Legal to act on your behalf.
                        </h3>
                        <br />
                        <Checkbox
                            fieldName={'acceptTerms'}
                            label={
                                'I agree that I have read, understand and accept the Sentinel Legal terms of business and that the check is free. I also agree to instruct Sentinel Legal to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold.'
                            }
                            saveValue={preSaveValueFunct}
                        />
                        <br />
                        <SignaturePad
                            extraPayload={{
                                browser: browserName,
                                device: osName,
                                type: 'sentinelSignAlt',
                                cases,
                            }}
                            additionalAction={() => animateBeforeAssign('upload')}
                            disclaimer={
                                'We will carry out a soft credit check to validate your case as well as verify your identity and prevent money laundering. This will not impact your credit rating in any way and only data relevant to Vehicle finance will be gathered from your file'
                            }
                        />
                        <br />
                        <div>
                            <h3>Your Claims</h3>
                            {cases &&
                                cases.map((singleCase: any) => {
                                    console.log('SINGLE CASE: ', singleCase)
                                    return (
                                        <div className={`${styles.singleClaim} ${responsiveStyles.singleClaim}`}>
                                            <p className={styles.claimTitle}>Your Details</p>

                                            <p>
                                                <span>Finance Provider:</span> {singleCase.leadData.lender}
                                            </p>
                                            <p>
                                                <span>Sentinel Reference:</span> {singleCase.leadData.case_ref}
                                            </p>
                                            <p className={styles.claimTitle}>Your Documents</p>
                                            <div>
                                                {singleCase?.loaUrl ? (
                                                    <Previewer url={singleCase.loaUrl} documentString={'LOA'} />
                                                ) : (
                                                    <></>
                                                )}
                                                {singleCase?.cfaUrl ? (
                                                    <Previewer url={singleCase.cfaUrl} documentString={'CFA'} />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
