import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'

export const ClaimBannerUL = () => {
    return (
        <div className={`${styles.formBanner} ${responsiveStyles.formBanner}`}>
            <Wrapper>
                <div>
                    <h1>
                        Make a <span>Irresponsible Lending Claim</span>
                    </h1>
                    <p>Start your claim with Sentinel Legal.</p>
                </div>
            </Wrapper>
        </div>
    )
}
