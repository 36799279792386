import { useState } from 'react'
import s from './styles.module.scss'

const Previewer = ({ url, documentString }: any) => {
    const [showPreview, setShowPreview] = useState(false)

    return (
        <div>
            <button onClick={() => setShowPreview(!showPreview)} className={s.previewLink}>
                Click here to preview your{' '}
                {documentString && documentString.length ? documentString + ' document' : 'document'}
            </button>
            <div className={`${s.previewer} ${showPreview ? s.show : ''}`}>
                <iframe src={url} height={450} width={300} />
            </div>
        </div>
    )
}
export default Previewer
