import { useEffect, useState } from 'react'
import s from './styles.module.scss'
import rs from './responsiveStyles.module.scss'
import { Link } from 'react-router-dom'
import { Data8Service } from '../../../services'

interface checkboxProps {
    fieldName: string
    label: string
    saveValue: (obj: { field: string; value: string }) => { success: boolean; error: string }
}

export const Checkbox = (props: checkboxProps) => {
    const { fieldName, saveValue, label } = props
    const [boxChecked, setBoxChecked] = useState(false)

    useEffect(() => {
        if (boxChecked) {
            saveValue({ field: fieldName, value: boxChecked ? 'yes' : 'no' })
        }
    }, [boxChecked])

    const click = async (e: React.MouseEvent) => {
        setBoxChecked(!boxChecked)
    }

    return (
        <div className={`${s.checkbox} ${rs.checkbox}`}>
            <p onClick={click}>
                <span className={`${boxChecked ? s.checked : ''}`}></span>
                {label}
            </p>
        </div>
    )
}
