import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import {
    InputFieldText,
    InputFieldSelect,
    FieldGroup,
    Field,
    SubmitButton,
    ButtonContainer,
    FieldContainer,
    FormCard,
    SelectButton,
    CtaButton,
} from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { sectionValidation } from '../../../helpers'
import { dvlaCheckService } from '../../../services/dvla-check'
import { Loading } from 'notiflix'
import { useClaimData } from '../../../claimProvider'

const initialLenderDetails = {
    lenderId: '',
    lender: '',
    registration: '',
    make: '',
    colour: '',
    carFuelType: '',
    carYear: '',
    registrationYear: '',
}

export const VehicleDetails = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})
    const [lenderVehicles, setLenderVehicles] = useState<any>([])
    const claim = useClaimData()

    useEffect(() => {
        // console.log("CLAIM DATA: ", claim);
        if (isActive == 'vehicle') {
            let tempLenderVehicles = []
            for (let index = 0; index < claim.lender.length; index++) {
                const singlelender = claim.lender[index]
                let singlelenderData = { ...initialLenderDetails, lender: singlelender, lenderId: index + 1 }
                tempLenderVehicles.push(singlelenderData)
            }
            setLenderVehicles(tempLenderVehicles)
        }
    }, [claim, isActive])

    useEffect(() => {
        const saved = saveValueFunct({ field: 'lender', value: lenderVehicles })
    }, [lenderVehicles])

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct({ field: 'lender', value: lenderVehicles })
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)
        return saved
    }

    const animateBeforeAssign = (assignment: string) => {
        // if (!sectionValidation(stepData, "vehicle").success) return;
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const populateCarDetails = async (lenderId: string) => {
        Loading.standard('Checking Vehicle...')
        let tempLenderVehicles = [...lenderVehicles]

        let carDataToUpdate = tempLenderVehicles.find((lv: any) => lv.lenderId == lenderId)
        const carData = await dvlaCheckService(carDataToUpdate.registration)

        if (!carData.success) {
            Loading.remove()
            Report.failure('Invalid registration.', 'Please enter a valid registration.', 'OK')
            return
        }

        carDataToUpdate.make = carData.make
        carDataToUpdate.colour = carData.colour
        carDataToUpdate.carFuelType = carData.fuelType
        carDataToUpdate.carYear = String(carData.year)
        carDataToUpdate.registrationYear = String(carData.registrationYear)

        for (let index = 0; index < tempLenderVehicles.length; index++) {
            let tempVehicle = tempLenderVehicles[index]
            if (tempVehicle.lenderId == lenderId) {
                tempVehicle = { ...carDataToUpdate }
            }
        }

        // console.log(tempLenderVehicles);
        setLenderVehicles(tempLenderVehicles)
        Loading.remove()
    }

    const updateLenderReg = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.currentTarget.value
        const lenderIdToUpdate = e.currentTarget.getAttribute('data-lender-id')
        let tempLenderVehicles = [...lenderVehicles]
        for (let index = 0; index < tempLenderVehicles.length; index++) {
            const lenderVehicle = tempLenderVehicles[index]
            if (lenderVehicle.lenderId == lenderIdToUpdate) {
                lenderVehicle.registration = newValue
            }
        }
        setLenderVehicles(tempLenderVehicles)
    }

    const getCurrentStep = () => {
        return isActive == 'vehicle' ? (
            <FieldGroup title="Vehicle Details">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <h3 className={styles.VehicleDetailsTitle}>Vehicle Details</h3>
                    {lenderVehicles &&
                        lenderVehicles.map((singleLender: any, index: number) => {
                            return (
                                <div
                                    className={`${styles.vehicleRegistrationSection} ${responsiveStyles.vehicleRegistrationSection}`}
                                    key={index}
                                >
                                    <label>
                                        Enter the vehicle registration for finance provided by{' '}
                                        <b>{singleLender.lender}</b> (Optional)
                                    </label>
                                    <div className={`${styles.vehicleCheckField}`}>
                                        <input
                                            type="text"
                                            data-lender-id={singleLender.lenderId}
                                            name={`reg-lender-${singleLender.lenderId}`}
                                            onChange={(e) => updateLenderReg(e)}
                                        />
                                        <button
                                            className={`${styles.checkRegButton}`}
                                            onClick={() => populateCarDetails(singleLender.lenderId)}
                                        >
                                            CHECK
                                        </button>
                                    </div>
                                    <div
                                        className={`${singleLender.make !== '' ? styles.displayResults : ''} ${
                                            styles.dvlaResults
                                        } ${responsiveStyles.dvlaResults}`}
                                    >
                                        <div>
                                            <p>
                                                Vehicle Make: <b>{singleLender.make}</b>{' '}
                                            </p>
                                            <p>
                                                Vehicle Colour: <b>{singleLender.colour}</b>
                                            </p>
                                            <p>
                                                Vehicle Fuel Type: <b>{singleLender.carFuelType}</b>{' '}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                Vehicle Registration Year: <b>{singleLender.registrationYear}</b>
                                            </p>
                                            <p>
                                                Vehicle Year: <b>{singleLender.carYear}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('person')} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
