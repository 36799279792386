import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Link } from 'react-router-dom'
import { Data8Service } from '../../../services'
import { colors } from 'react-select/dist/declarations/src/theme'

interface chevronProps {
    chev1Colour: string
    chev2Colour: string
}

export const Chevron = (props: chevronProps) => {
    const { chev1Colour, chev2Colour } = props

    useEffect(() => {
        if (chev1Colour) {
            console.log('colour:', chev1Colour)
        }
    }, [chev1Colour])

    return (
        <div className={`${styles.chevron} ${responsiveStyles.chevron}`}>
            <div
                className={`${styles.chev1} ${responsiveStyles.chev1}`}
                style={{ borderColor: `#FFFFFF #FFFFFF #FFFFFF ${chev1Colour}` }}
            ></div>
            <div
                className={`${styles.chev2} ${responsiveStyles.chev2}`}
                style={{ borderColor: `${chev2Colour} ${chev2Colour} ${chev2Colour} transparent` }}
            ></div>
        </div>
    )
}
