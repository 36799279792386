import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import 'react-rangeslider/lib/index.css'
import { lenderDataMain, lenderDataSecondary } from '../../../../data/lenders'
import arrrowDown from '../../../../assets/arrow-down.svg'
import {
    InputFieldText,
    InputFieldSelect,
    FieldGroup,
    Field,
    SubmitButton,
    ButtonContainer,
    FieldContainer,
    FormCard,
    SelectButton,
    OptionButton,
    CtaButton,
} from '../../../reusable'

export interface lenderButtonsInterface {
    callback: (lenders: String[]) => Boolean
}

export const LenderButtonGroup = (props: lenderButtonsInterface) => {
    const { callback } = props
    const [selectedLenders, setSelectedLenders] = useState<Array<string>>([])
    const [displayMoreLenders, setDisplayMoreLenders] = useState(false)

    // useEffect(() => {
    //     if (selectedLenders.length > 0) callback(selectedLenders);
    // }, [selectedLenders]);

    const selectLender = (elem: string) => {
        let tempLenders = [...selectedLenders]

        if (tempLenders && !tempLenders.length) {
            tempLenders.push(elem)
        } else {
            const exists = tempLenders.some((searchedLender) => searchedLender == elem)
            const updated = !exists
                ? tempLenders.push(elem)
                : (tempLenders = tempLenders.filter((filterringLender) => filterringLender != elem))
        }
        setSelectedLenders(tempLenders)
    }

    return (
        <div className={`${styles.lenderButtonGroup} ${responsiveStyles.lenderButtonGroup}`}>
            <p>Select your finance providers</p>
            <div className={`${styles.lenderButtons} ${responsiveStyles.lenderButtons}`}>
                {lenderDataMain.map((elem, i) => {
                    return (
                        <SelectButton
                            key={i}
                            btnType={'option'}
                            text={elem.label}
                            onClick={() => selectLender(elem.value)}
                            isSelected={selectedLenders.some((arrayElem) => arrayElem == elem.value)}
                        />
                    )
                })}
            </div>
            <p
                className={`${styles.showMoreLenders}  ${displayMoreLenders ? styles.flipArrow : ''}  `}
                onClick={() => setDisplayMoreLenders(!displayMoreLenders)}
            >
                Show more lenders
                <img src={arrrowDown} />
            </p>
            <div
                className={`${styles.moreLenderButtons} ${responsiveStyles.moreLenderButtons} ${
                    displayMoreLenders ? styles.showMoreLenders : ''
                } `}
            >
                {lenderDataSecondary.map((elem, i) => {
                    return (
                        <OptionButton
                            key={i}
                            btnType={'option'}
                            text={elem.label}
                            onClick={() => {
                                selectLender(elem.value)
                            }}
                            isSelected={selectedLenders.some((arrayElem) => arrayElem == elem.value)}
                        />
                    )
                })}
            </div>
            <div>
                <SubmitButton
                    text={'Next'}
                    onClick={() => {
                        callback(selectedLenders)
                    }}
                    btnType="primary"
                />
            </div>
        </div>
    )
}
/**
 * 
                                        ? 
                                        : 
 */
