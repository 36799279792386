import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../assets/carclaimslogo.png";
import slogo from "../../../assets/logo.png";
import { Wrapper } from "../../reusable";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={`${styles.footer} ${responsiveStyles.footer}`}>
      <Wrapper>
        <div
          className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}
        >
          <div className={styles.left}>
            <div>
              <img src={slogo} />
              <p className={styles.footerLink}>
                <a href="mailto:contact@sentinellegal.co.uk">
                  Email: contact@sentinellegal.co.uk
                </a>{" "}
                | <a href="tel:01615289544">Phone: 0161 528 9544</a>
              </p>
            </div>
            <div className={styles.footerbottom}>
              <p>
                <a
                  href="https://www.sentinellegal.co.uk/privacy-policy"
                  target="__blank"
                >
                  View our Privacy and Complaints Policy Here
                </a>
              </p>
              <p>
                Sentinel legal Limited is Registered in England and Wales.
                Registered office Harley house, 29 Cambray Place, Cheltenham,
                England, GL50 1JN. Company number 12826774. VAT registration
                number 423 9685 69
                <br /> Authorised and regulated by the Solicitors Regulation
                Authority. SRA number 811792. <br />
                Phone: 0161 528 9544
              </p>
            </div>
          </div>
          <div className={styles.right}>
            <iframe
              className={styles.sraIframe}
              title="Embedded Content"
              name="htmlComp-iframe"
              width="100%"
              height="100%"
              allow="fullscreen"
              data-src=""
              src="https://www-sentinellegal-co-uk.filesusr.com/html/8a5699_06ae1edd2bd90a092e397c99be04a3e8.html"
            ></iframe>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default Footer;
