import { useEffect, useState } from "react";
import s from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Data8Service } from "../../../services";

interface inputFieldTextProps {
    fieldName: string;
    saveValue: (obj: { field: string; value: string }) => { success: boolean; error: string };
    submitValue?: any;
    fieldType?: string | null;
    populateValue?: string;
}

export const InputFieldText = (props: inputFieldTextProps) => {
    const { fieldName, saveValue, fieldType, populateValue } = props;
    const [valueToSave, setValueToSave] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        console.log("VALUE TO POPULATE", populateValue);
        if (typeof populateValue != "undefined" && populateValue !== "") {
            saveAll(populateValue);
        }
    }, [populateValue]);

    useEffect(() => {}, []);

    const onChangeFunc = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.currentTarget.value;
        setValueToSave(newValue);
        let toSave = { field: fieldName, value: newValue };
        const isValid = await data8Validation(newValue);
        console.log(fieldName, " is valid? ", isValid);

        if (!isValid) toSave.value = "";
        const saveResult = saveValue(toSave);
        console.log("save result: ", saveResult);

        saveResult.success ? setError("") : setError(saveResult.error);
    };

    const saveAll = async (newValue: string) => {
        // if (!isValid) {
        //     const saveResult = saveValue({ field: fieldName, value: "" });
        //     return;
        // }
    };

    const data8Validation = async (newValue: string) => {
        if (typeof fieldType != "undefined" && fieldType == "email" && newValue.length > 0) {
            const data8Valid = await Data8Service("EmailValidation", "IsValid", { email: newValue });
            if (data8Valid.Result === "Invalid") {
                setError("Invalid email");
                return false;
            }
        }
        if (typeof fieldType != "undefined" && fieldType == "tel" && newValue.length > 0) {
            const data8Valid = await Data8Service("PhoneValidation", "IsValid", { telephoneNumber: newValue, defaultCountry: "GB" });
            // console.log(data8Valid);
            if (data8Valid.Result.ValidationResult === "Invalid") {
                setError("Invalid telephone number");
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <input
                className={`${s.inputStyle} ${fieldName == "registration" ? s.uppercase : ""}`}
                // readOnly={typeof populateValue != "undefined" ? true : false}
                type={`${fieldType != null ? fieldType : "text"}`}
                name={fieldName}
                value={typeof populateValue != "undefined" ? populateValue : valueToSave}
                onChange={onChangeFunc}
                // onBlur={() => data8Validation()}
            />
            <span className={`${s.notice} {${error.length < 1 ? "error" : ""}`}>{error}</span>
        </>
    );
};
