import { Report } from 'notiflix/build/notiflix-report-aio'
import { Loading } from 'notiflix'

/**
 * "Couldn't retrieve company information"
 * 'Lead already signed'
 */
export const apiErrors = (serverError: string) => {
    if (serverError === 'Token not found' || serverError === 'no token' || serverError == 'Claim not found') {
        Report.failure(
            'Unable to locate account.',
            "We were unable to locate your case, contact support@sentinellegal.co.uk' for more information.",
            'OK',
            () => (window.location.href = 'https://sentinellegal.co.uk')
        )
    } else if (serverError === 'ID Already uploaded') {
        Report.failure(
            'ID Already Received.',
            "We've already received your proof of ID.",
            'OK',
            () => (window.location.href = 'https://sentinellegal.co.uk')
        )
    } else if (serverError === 'Already signed') {
        Report.failure(
            'Documents already signed',
            "You've already signed your CFA.",
            'OK',
            () => (window.location.href = 'https://sentinellegal.co.uk')
        )
    } else {
        console.log('ERROR - fail...')

        Report.failure('Internal Error', "We've encountered an internal error, please try again later.", 'OK', () => {
            // window.location.href = "https://google.co.uk";
        })
    }

    return true
}
