import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop'

import Main from './containers/main'
import MakeClaim from './containers/makeClaim'
import UploadDocument from './containers/uploadId'
import SignDocuments from './containers/signDocuments'
import MakeClaimIL from './containers/makeClaimIL'
import SignDocumentsIL from './containers/signDocumentIL'
import UploadDocumentIL from './containers/uploadIdIL'

export default function AppRouter() {
    // TEST
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route path="start" element={<MakeClaim />} />
                    <Route path="sign-sentinel-cfa/:token" element={<SignDocuments docsToSign="sentinel-cfa_alt" />} />
                    <Route path="upload-id/:token" element={<UploadDocument />} />
                    <Route path="irresponsible-lending">
                        <Route path="start" element={<MakeClaimIL />} />
                        <Route
                            path="sign-sentinel-ul-cfa/:token"
                            element={<SignDocumentsIL docsToSign="sentinel-il-cfa" />}
                        />
                        <Route path="upload-id/:token" element={<UploadDocumentIL />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
}
