import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import axios from 'axios'
import { useParams } from 'react-router-dom'
import Notiflix, { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../../reusable'

import stepArrow from '../../../assets/step-arrow2.svg'

import { SaveValueInterface } from '../../../interfaces'
import { fieldValidation, uploadFileToServer } from '../../../helpers'
import { filesArrayInterface } from '../../../interfaces'
import { UploadDocuments, Complete, SignatureOA } from '../../formParts'
import { SignatureHyperian } from '../../formParts/signatureHyperian'
import { SignatureCCD } from '../../formParts/signatureCCD'
import { CompleteSigned } from '../../formParts/completSigned'
import { SignatureSentinel } from '../../formParts/signatureSentinel'
import { IdResumeBanner } from '../../bannerResumeIdUpload'
import { SignatureResumeBanner } from '../../bannerResumeSignature'
import { SignatureILSentinel } from '../../formParts/signatureILSentinel'
import { UploadILDocuments } from '../../formParts/uploadIL'

interface sdInterface {
    docsToSign: string
}

function SignatureFormIL({ docsToSign }: sdInterface) {
    const [claimObject, setClaimObject] = useState<any>({})
    const [groupsComplete, setGroupsComplete] = useState(0)
    const [activeGroup, setActiveGroup] = useState('signature')

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        return { success: true, error: '' }
    }

    const activeGroupAssignment = async (group: string) => {
        console.log('next group to display: ', group)
        console.log('CLAIM OBEJECT : ', claimObject)
        setActiveGroup(group)
        window.scrollTo(0, 0)
        setGroupsComplete(groupsComplete + 2)
    }

    return (
        // <Wrapper>
        <>
            <SignatureResumeBanner name={''} />
            <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
                <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                    <div className={styles.formFields}>
                        <SignatureILSentinel
                            saveValueFunct={saveFieldValue}
                            isActive={activeGroup}
                            assignGroup={activeGroupAssignment}
                        />{' '}
                        <UploadILDocuments
                            saveValueFunct={saveFieldValue}
                            isActive={activeGroup}
                            assignGroup={activeGroupAssignment}
                        />
                        <Complete
                            isActive={activeGroup}
                            saveValueFunct={saveFieldValue}
                            assignGroup={activeGroupAssignment}
                        />
                    </div>
                </form>
            </div>
        </>
        // </Wrapper>
    )
}

export default SignatureFormIL
