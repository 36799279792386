import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import axios from 'axios'
import { useParams } from 'react-router-dom'
import Notiflix, { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../../reusable'

import stepArrow from '../../../assets/step-arrow2.svg'

import { SaveValueInterface } from '../../../interfaces'
import { fieldValidation, uploadFileToServer } from '../../../helpers'
import { filesArrayInterface } from '../../../interfaces'
import { Complete } from '../../formParts'
import { useClaimData } from '../../../claimProvider'
import { ClaimBanner } from '../../bannerClaim'
import { IdResumeBanner } from '../../bannerResumeIdUpload'
import { UploadILDocuments } from '../../formParts/uploadIL'

const steps = [
    {
        text: 'Upload ID',
        transitionPoint: 0,
        step: 1,
    },
    {
        text: 'Complete Application',
        transitionPoint: 1,
        step: 2,
    },
]

function UploadFormIL() {
    const [claimObject, setClaimObject] = useState<any>({})
    const [groupsComplete, setGroupsComplete] = useState(0)
    const [activeGroup, setActiveGroup] = useState('upload')
    const [firstname, setFirstname] = useState('')
    const claimData = useClaimData()
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    useEffect(() => {
        if (claimData != null) {
            setClaimObject(claimData)
            setFirstname(claimData.claim.firstname)
        }
    }, [claimData])

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        return { success: true, error: '' }
    }

    const activeGroupAssignment = async (group: string) => {
        console.log('next group to display: ', group)
        console.log('CLAIM OBEJECT : ', claimObject)
        setActiveGroup(group)
        window.scrollTo(0, 0)
        setGroupsComplete(groupsComplete + 2)
    }

    return (
        <>
            <IdResumeBanner name={firstname} />
            <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
                <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                    <div className={styles.formFields}>
                        <UploadILDocuments
                            saveValueFunct={saveFieldValue}
                            isActive={activeGroup}
                            assignGroup={activeGroupAssignment}
                        />
                        <Complete
                            isActive={activeGroup}
                            saveValueFunct={saveFieldValue}
                            assignGroup={activeGroupAssignment}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default UploadFormIL
