import styles from '../globals/pages.module.scss'

import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { apiErrors } from '../helpers/apiErrors'
import ClaimForm from '../components/claimForm/main'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import UploadForm from '../components/uploadForm/main'
import { useUpdateClaimData } from '../claimProvider'
import SignatureForm from '../components/signatureForm/main'
import SignatureFormIL from '../components/signatureFormIL/main'

interface sdInterface {
    docsToSign: string
}

function SignDocumentsIL({ docsToSign }: sdInterface) {
    const [resumeClaimData, setResumeClaimData] = useState<any>()
    const [requestingClaim, setRequestingClaim] = useState(false)
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()

    useEffect(() => {
        if (!requestingClaim) {
            setRequestingClaim(true)

            axios
                .post(`${process.env.REACT_APP_API_RESUME_SIGNATURE_IL_CLAIM}`, { token, leadOrigin: docsToSign })
                .then((response) => {
                    const resumeData = response.data
                    console.log('resumeData:', resumeData)
                    if (!resumeData.success) throw new Error(resumeData.error)
                    setResumeClaimData(resumeData)
                    updateClaim(resumeData.claimData)

                    // Loading.remove();
                })
                .catch((e) => {
                    console.log('error:', e)
                    Loading.remove()
                    apiErrors(e.message)
                })
        }
    }, [requestingClaim])

    return (
        <div className={`${styles.pcpClaimFormContainer}`}>
            <SignatureFormIL docsToSign={docsToSign} />
        </div>
    )
}

export default SignDocumentsIL
